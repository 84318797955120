/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PopulateInvoiceInfoModal = {
    status: PopulateInvoiceInfoModal.status;
    body: {
        statement_id: number;
        organization_name: string;
        facility_name: string;
        facility_id?: string;
        street_address: string;
        location: string;
        invoice_due_date: string;
        date_received: string;
        total_amount_due: number;
        balance_forward: number;
        invoice_total: number;
        total_taxes: number;
        invoice_url: string;
        balance_forward_credit: number;
        credits: number;
        invoice_date: string;
        invoice_period_start_date: string;
        invoice_period_end_date: string;
        late_fee: number;
        alerts: Array<{
            date: string;
            name: string;
            level: number;
            status: string;
        }>;
        meter_data: Array<{
            meter_id: string;
            read_type: string;
            account_id: string;
            account_number: string;
            date_received?: string;
            consumption: number;
            meter_number: string;
            total_charges: number;
            meter_read_end: string;
            '24_months_chart': Array<{
                date: string;
                charges: number;
                month_year: string;
                consumption: number;
                charge_currency: string;
                usage_unit_of_measure: string;
            }>;
            meter_commodity: string;
            meter_read_start: string;
            record_commodity: string;
            editable_entities: {
                record_id?: string;
                adjusted_usage?: number;
                adjustment_factor?: number;
                bundled_charges?: number;
                carbon_tax?: number;
                capacity_charge?: number;
                consumption_charge?: number;
                consumption_usage?: number;
                cost_adjustment?: number;
                customer_charge?: number;
                delivery_charge_consumption_based?: number;
                delivery_charge_demand_based?: number;
                demand_charge?: number;
                demand_consumption?: number;
                demand_uom?: string;
                distribution_charge?: number;
                federal_tax?: number;
                fixed_charge?: number;
                gst?: number;
                hst?: number;
                local_tax?: number;
                meter_usage_previous?: number;
                mid_peak_power_charge?: number;
                off_peak_power_charge?: number;
                on_peak_power_charge?: number;
                other_charges?: number;
                other_tax?: number;
                penalties?: number;
                meter_period_start_date?: string;
                meter_period_end_date?: string;
                power_factor?: number;
                pst?: number;
                qst?: number;
                rate_rider_charge?: number;
                renewable_power_charge?: number;
                state_tax?: number;
                supply_charge?: number;
                total_charges?: number;
                transportation_transmission_charge?: number;
                usage_unit_of_measure?: string;
                regulatory_charge?: number;
            };
            usage_unit_of_measure: string;
            invoice_total_amount_due: number;
        }>;
    };
    message: string;
};

export namespace PopulateInvoiceInfoModal {

    export enum status {
        SUCCESS = 'Success',
    }


}

