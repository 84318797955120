/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetCommodityTypes = {
    status: GetCommodityTypes.status;
    body: Array<{
        id: string;
        name: string;
        scope: number;
    }>;
    message: string;
};

export namespace GetCommodityTypes {

    export enum status {
        SUCCESS = 'Success',
    }


}

