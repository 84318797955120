/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_upload_single_file_api_v2_pipeline_upload_one_post } from '../models/Body_upload_single_file_api_v2_pipeline_upload_one_post';
import type { SfnSchema } from '../models/SfnSchema';
import type { UploadOneResponse } from '../models/UploadOneResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PipelineService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Upload Single File
     * Upload a file to the pipeline for processing
     *
     * ### *To be used with frontend upload file component*
     * @param formData
     * @returns UploadOneResponse File submitted to pipeline
     * @throws ApiError
     */
    public uploadSingleFileApiV2PipelineUploadOnePost(
        formData: Body_upload_single_file_api_v2_pipeline_upload_one_post,
    ): CancelablePromise<UploadOneResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2/pipeline/upload/one',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request Error`,
                404: `Duplicate File Detected`,
                415: `File not allowed`,
                422: `Validation Error`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Sfn Read Single Execution Status
     * @param executionArn
     * @returns SfnSchema Success
     * @throws ApiError
     */
    public sfnReadSingleExecutionStatusApiV2ReadSingleSfnExecutionGet(
        executionArn: string,
    ): CancelablePromise<SfnSchema> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v2/read/single/sfn-execution',
            query: {
                'execution_arn': executionArn,
            },
            errors: {
                400: `Bad Request Error`,
                404: `Not Found Error`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
