/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type getAllReportsFilters = {
    status: getAllReportsFilters.status;
    body: {
        commodity_types: Array<{
            commodity_type_id: string;
            commodity_type_name: string;
        }>;
        organization_accounts: Array<{
            account_id: string;
            account_number: string;
            account_name: string;
        }>;
        organization_structure: Array<{
            division_name: string;
            groups: Array<{
                group_name: string;
                facilities: Array<string>;
            }>;
        }>;
        utility_companies: Array<{
            utility_id: string;
            utility_name: string;
        }>;
        countries: Array<string>;
        facility_list: Array<{
            facility_id: string;
            facility_name: string;
            facility_address: {
                address?: string;
                city?: string;
                postal_zip_code?: string;
                province?: string;
                country?: string;
            };
            group_id: string;
            group_name: string;
            division_id: string;
            division_name: string;
        }>;
    };
    message: string;
};

export namespace getAllReportsFilters {

    export enum status {
        SUCCESS = 'Success',
    }


}

