/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type getUtilityCompanies = {
    status: getUtilityCompanies.status;
    /**
     * Successfully retrieved utility companies
     */
    message: string;
    body: Array<{
        id: string;
        name: string;
    }>;
};

export namespace getUtilityCompanies {

    export enum status {
        SUCCESS = 'Success',
    }


}

