/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * database name used in mongodb crud operations
 */
export enum CollectionNames {
    STAGE_ONE_STAGE_TWO = 'stage_one.stage_two',
    UTILITY_TEMPLATE = 'utility_template',
    PROMPTS = 'prompts',
}
