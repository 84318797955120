/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type getUnbundledCostReport = {
    status: getUnbundledCostReport.status;
    message: string;
    body: {
        unbundled_cost: Array<{
            the_date: string;
            consumption: number;
            usage_unit_of_measure: string;
            facility_id: string;
            facility_name: string;
            group_id: string;
            group_name: string;
            division_id: string;
            division_name: string;
            country: string;
            commodity_id: string;
            commodity_name: string;
            delivery_charge_consumption_based_cad: number;
            delivery_charge_consumption_based_usd: number;
            delivery_rate_cad: number;
            delivery_rate_usd: number;
            supply_charge_cad: number;
            supply_charge_usd: number;
            supply_rate_cad: number;
            supply_rate_usd: number;
            bundled_charges_cad: number;
            bundled_charges_usd: number;
            bundled_rate_cad: number;
            bundled_rate_usd: number;
            total_cad: number;
            total_usd: number;
        }>;
    };
};

export namespace getUnbundledCostReport {

    export enum status {
        SUCCESS = 'Success',
    }


}

