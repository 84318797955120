/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type putEditFilterLandingPageTargetDashboardResponse = {
    status: putEditFilterLandingPageTargetDashboardResponse.status;
    message: string;
    response: {
        check: putEditFilterLandingPageTargetDashboardResponse.check;
    };
};

export namespace putEditFilterLandingPageTargetDashboardResponse {

    export enum status {
        SUCCESS = 'Success',
        FAILED = 'Failed',
    }

    export enum check {
        TRUE = 'True',
        FALSE = 'False',
    }


}

