/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type postTargetDashboardStructureModelResponse = {
    status: postTargetDashboardStructureModelResponse.status;
    message: string;
    body: {
        Organization: {
            id: string;
            name: string;
            cost_cad: number;
            cost_usd: number;
            cons: number;
            emissions: number;
        };
        Division: {
            id: string;
            name: string;
            cost_cad: number;
            cost_usd: number;
            cons: number;
            emissions: number;
        };
        Group: {
            id: string;
            name: string;
            cost_cad: number;
            cost_usd: number;
            cons: number;
            emissions: number;
        };
        Facility: {
            id: string;
            name: string;
            cost_cad: number;
            cost_usd: number;
            cons: number;
            emissions: number;
        };
    };
};

export namespace postTargetDashboardStructureModelResponse {

    export enum status {
        SUCCESS = 'Success',
        ERROR = 'Error',
    }


}

