/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type getEmissionsRegionsAndYears = {
    status: getEmissionsRegionsAndYears.status;
    message: string;
    body: Array<{
        country?: string;
        region: string;
        years: Array<number>;
    }>;
};

export namespace getEmissionsRegionsAndYears {

    export enum status {
        SUCCESS = 'Success',
        FAILURE = 'Failure',
    }


}

