/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type postCreateFilterLandingPageTargetDashboardresponse = {
    status: postCreateFilterLandingPageTargetDashboardresponse.status;
    message: string;
    response: {
        filter_id: string;
    };
};

export namespace postCreateFilterLandingPageTargetDashboardresponse {

    export enum status {
        SUCCESS = 'Success',
        FAILED = 'Failed',
    }


}

