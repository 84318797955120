// 1. QUERIES
enum CommonQueryKey {
	DocumentCount = 'documentCount'
}
enum ConstantsQueryKey {
	SingleConstantsObject = 'singleConstantsObject'
}
enum DownloadQueryKey {
	SingleFile = 'singleFile',
	SingleBase64File = 'singleBase64File'
}
enum EcsHealthCheckQueryKey {
	SingleHealthCheck = 'singleHealthCheck'
}
enum InvoicesQueryKey {
	AllInvoices = 'allInvoices',
	ReadInvoice = 'ReadInvoice',
	SingleInvoice = 'singleInvoice',
	UniqueFieldOr404 = 'uniqueFieldOr404'
}
enum PipelineQueryKey {
	SingleSfnExecutionStatus = 'singleSfnExecutionStatus'
}
enum PromptsQueryKey {
	AllPrompts = 'allPrompts',
	SinglePrompt = 'singlePrompt'
}
enum UtilitiesQueryKey {
	AllUtilities = 'allUtilities',
	SingleUtility = 'singleUtility'
}

/**
 * Represents the query keys for the application.
 */
export const QUERY_KEYS = Object.freeze({
	common: CommonQueryKey,
	constants: ConstantsQueryKey,
	download: DownloadQueryKey,
	ecsHealthCheck: EcsHealthCheckQueryKey,
	invoices: InvoicesQueryKey,
	pipeline: PipelineQueryKey,
	prompts: PromptsQueryKey,
	utilities: UtilitiesQueryKey
});

/**
 * Represents the query keys for the application.
 */
export type QueryKey =
	| CommonQueryKey
	| ConstantsQueryKey
	| DownloadQueryKey
	| EcsHealthCheckQueryKey
	| InvoicesQueryKey
	| PipelineQueryKey
	| PromptsQueryKey
	| UtilitiesQueryKey;

// 2. MUTATIONS
enum AiModelsMutationKey {
	ZeroShotModelPredictionOr404 = 'zeroShotModelPredictionOr404',
	LayoutlmModelPredictionOr404 = 'layoutlmModelPredictionOr404'
}

enum DeleteMutationKey {
	DeleteAllFromDbAndS3 = 'deleteAllFromDbAndS3',
	DeleteSingleFromCollectionById = 'deleteSingleFromCollectionById',
	DeleteSingleDocumentById = 'deleteSingleDocumentById'
}

enum InvoicesMutationKey {
	UpdateInvoice = 'updateInvoice',
	UpdateAlerts = 'updateAlerts',
	SubmitInvoiceToSql = 'submitInvoiceToSql'
}

enum PipelineMutationKey {
	UploadSingleFile = 'uploadSingleFile'
}

enum PromptsMutationKey {
	CreatePrompt = 'createPrompt',
	UpdatePrompt = 'updatePrompt',
	UpdatePrompts = 'updatePrompts'
}

enum UtilitiesMutationKey {
	CreateUtility = 'createUtility',
	UpdateUtility = 'updateUtility'
}

/**
 * Represents the mutation keys for the application.
 */
export const MUTATION_KEYS = Object.freeze({
	aiModels: AiModelsMutationKey,
	delete: DeleteMutationKey,
	invoices: InvoicesMutationKey,
	pipeline: PipelineMutationKey,
	prompts: PromptsMutationKey,
	utilities: UtilitiesMutationKey
});

/**
 * Represents the mutation keys for the application.
 */
export type MutationKey =
	| AiModelsMutationKey
	| DeleteMutationKey
	| InvoicesMutationKey
	| PipelineMutationKey
	| PromptsMutationKey
	| UtilitiesMutationKey;
