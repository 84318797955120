/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type populateLandingPageTargetDashboardResponse = {
    status: populateLandingPageTargetDashboardResponse.status;
    message: string;
    body: {
        years: Array<number>;
        filters: Array<any>;
        data: any;
    };
};

export namespace populateLandingPageTargetDashboardResponse {

    export enum status {
        SUCCESS = 'Success',
        FAILED = 'Failed',
    }


}

